



























































import { Component, Vue } from "vue-property-decorator";
import MyList from "@/components/MyList.vue";
import Mixin from "@/js/mixins";
import { Toast } from "vant";

@Component({ components: { MyList }, mixins: [Mixin] })
export default class UserBankBalance extends Vue {
  public user_id = "";
  public order_id = "";
  public list: any[] = []; // 数据的列表
//   public refresh = 0; // 页面允许刷新（为了list的改变）
    public no_pay_amount = '';
    public pay_amount = '';

  clearList() {
    this.list = [];
  }
  setList(list: any[]) {
    this.list.push(...list);
  }
  getList(){
      let _this = this
      this.$api.request({
      loading: "",
      url: 'common/pay-data-log/get-region-pay-data-log-all',
      data: {
        user_id:this.user_id
      },
      success(res) {
        _this.list = res.data
      },
      fail() {
        
      }
    });
  }

  // 提交表单
//   sub() {
//       console.log('123')
//     let _this = this;
//     let url = "common/pay-data-log/add-pay-data-log";
//     this.$api.request({
//       url,
//       data: {
//         user_id: this.user_id,
//         order_id: this.order_id,
//         is_region: 1
//       },
//       success(res) {
//         Toast("已提交审核，等待审核中...");
//       }
//     });
//   }

  init() {
    this.user_id = String(this.$route.query.user_id || "");
    this.order_id = String(this.$route.query.order_id || "");
    this.no_pay_amount = String(this.$route.query.no_pay_amount || "");
    this.pay_amount = String(this.$route.query.pay_amount || "");
    this.list = [];
    this.getList()
    // this.refresh++;
  }
}
